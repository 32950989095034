<template>
  <ModalFormGeneric :id="COMPNAME" :title="$t('coco.h')" :btnText="$t('btn.p')" btnIcon="e" :hidePic="true"
      @submit="onSubmit"
      @reset="onReset"
      :ref="COMPNAME">
    <LangFormDropdown id="coCoLang" :value="lang" :autoload="true" class="mb-3" />
    <b-form-row class="text-justify mx-0">
      <p>{{ $t('coco.t1') }}</p>
    </b-form-row>
    <b-list-group>
      <b-list-group-item v-for="(el, idx) in entries" :key="FORMNAME+`l`+idx">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <b-checkbox v-model="f[el.sc]" :disabled="el.disabled">
            {{ $t('coco.c'+idx+'h') }}
          </b-checkbox>
          <b-link v-b-toggle="`coCo`+idx" class="small">{{ $t('coco.d') }}</b-link>
        </div>
        <b-collapse :id="`coCo`+idx" class="text-justify">
          <p class="my-2 ml-4">{{ $t('coco.c'+idx+'t') }}</p>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
    <b-form-row class="text-justify mx-0 mt-3">
      <p>{{ $t('coco.t2') }}</p>
    </b-form-row>
  </ModalFormGeneric>
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';
import LangFormDropdown from '@/components/shared/LangFormDropdown';
import { loadLangAsync } from '@/plugins/i18n';

export default {
  data() {
    return {
      COMPNAME: 'cookieWindow',
      FORMNAME: 'cookieInput',
      f: {
        n: true,
        p: true,
        s: false,
        m: false,
        ts: new Date().toISOString(),
      },
      entries: [
        { disabled: true, sc: 'n' },
        { disabled: false, sc: 'p' },
        { disabled: true, sc: 's' },
        { disabled: true, sc: 'm' },
      ],
      loadedLang: false,
    }
  },
  created() {
    loadLangAsync(this.$store.state.settings.languageId, 'cookiecon', null).then(() => { this.loadedLang = true; });
  },
  computed: {
    lang() {
      return this.$store.state.settings.languageId;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$bvModal.show(this.COMPNAME);
    }, this.loadedLang ? 1 : 150); // maybe better to just show spinner until lang is loaded
  },
  methods: {
    onSubmit() {
      this.$refs[this.COMPNAME].hide();
      this.$store.commit('setCookieCfg', this.f);
      this.$emit('submit');
    },
    onReset() {
      // do not reset cookie data, but just do nothing
      this.$emit('reset');
    },
  },
  components: {
    ModalFormGeneric,
    LangFormDropdown,
  },
}
</script>
